import React, { useState } from 'react';
import { useTokenContext } from 'contexts/resource/ResourceContext';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const Notification = ({
  notificationsVisible,
  handleToggleNotifications,
}: any) => {
  const { notificationMessages } = useTokenContext();
  const [messagesVisible, setMessagesVisible] = useState(true);

  const handleToggleMessages = () => {
    setMessagesVisible(!messagesVisible);
  };

  return notificationsVisible ? (
    <div
      className="absolute left z-10 mt-72 flex w-screen max-w-max -translate-x-1/2"
      style={{
        backgroundColor: '#f0f0f0',
      }}
    >
      <div
        className="notification-arrow"
        style={{
          width: 0,
          height: 0,
          borderLeft: '10px solid transparent',
          borderRight: '10px solid transparent',
          borderBottom: '10px solid #f0f0f0',
          position: 'absolute',
          top: '-10px',
          right: '50px',
        }}
      />
      <div className="w-screen max-w-md flex-auto overflow-hidden text-sm leading-6 shadow-lg ring-2 ring-gray-900/6 bg-gradient-to-r from-gray-50 to-blue-100">
        <div>
          {JSON.parse(notificationMessages) &&
          JSON.parse(notificationMessages).length > 0 ? (
            <div
              id="messages"
              style={{ display: messagesVisible ? 'block' : 'none' }}
            >
              {JSON.parse(notificationMessages).map((item) => {
                const isSuccess = item.status === 'success';
                const isError = item.status === 'error';

                return (
                  <div
                    key={item?.id}
                    className={`group relative flex gap-x-6 p-4 ${
                      isSuccess ? 'border-green-500 bg-green-50' : ''
                    } ${isError ? 'border-red-500 bg-red-50' : ''}`}
                    style={{
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      borderRadius: '10px',
                      margin: '10px',
                    }}
                  >
                    <div
                      className={`mt-1 flex h-11 w-11 flex-none items-center justify-center group-hover:bg-white ${
                        isSuccess ? 'bg-green-200' : ''
                      } ${isError ? 'bg-red-200' : ''}`}
                    >
                      {isSuccess ? (
                        <CheckCircleOutlined className="text-green-600" />
                      ) : (
                        <CloseCircleOutlined className="text-red-600" />
                      )}
                    </div>
                    <div>
                      <a href="#" className="font-semibold text-gray-900">
                        {item?.title}{' '}
                        <span className="text-xs text-gray-400 animate-pulse">
                          {item?.timestamp}
                        </span>
                        <span className="absolute inset-0" />
                      </a>
                      <p className="mt-1 text-gray-600">{item?.message}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}

          <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-100">
            <button
              className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-blue-200"
              onClick={handleToggleMessages}
            >
              <i className="fa-solid fa-broom" />{' '}
              {messagesVisible ? 'Clear' : 'Undo'}
            </button>
            <button
              className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-blue-200"
              onClick={handleToggleNotifications}
            >
              <i className="fa-regular fa-circle-xmark" />{' '}
              {notificationsVisible ? 'Close' : 'Show Notifications'}
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Notification;
